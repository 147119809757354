import { createContext, useEffect, useState } from "react";

export function useHover(ref: React.RefObject<HTMLElement>) {
  const [hovering, setHovering] = useState(false),
    handleMouseOver = () => setHovering(true),
    handleMouseOut = () => setHovering(false);

  useEffect(() => {
    const node = ref?.current;
    if (!node) return;

    node.addEventListener("mouseover", handleMouseOver);
    node.addEventListener("mouseout", handleMouseOut);
    return () => {
      node.removeEventListener("mouseover", handleMouseOver);
      node.removeEventListener("mouseout", handleMouseOut);
    };
  }, [ref]);

  return hovering;
}

export const HoverContext = createContext<{
  hovering: boolean;
  setHovering(hovering: boolean): void;
}>({
  hovering: false,
  /* istanbul ignore next */
  setHovering: () => undefined
});
